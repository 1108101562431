body {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
}

img {
  max-width: 100%;
}

.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.nav-logo {
  position: absolute;
  margin-top: 24px;
  margin-left: 32px;
  /* top: 5%;
  left: 5%; */
  z-index: 10;
}

h1, h2, h3, h4, h5 {
  color: #103F3B;
  font-size: 32px;
  line-height: 36px;
}

p {
  color: #103F3B;
  font-size: 18px;
  line-height: 24px;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.divider {
  /* display: flex; */
  background: #F0F2F2;
  min-width: 2px;
  min-height: 56px;
  height: 80%;
  align-self: center;
}

.horizontal-divider {
  /* display: flex; */
  background: #F0F2F2;
  width: 90%;
  min-height: 2px;
  align-self: center;
}

.top-spacer {
  margin-top: calc(24px + 56px + 8px);
  padding-top: 0px;
}